<template>
	<div>
		<p class="toBack" @click="toBack" v-if="checkVal != 2"><i class="el-icon-back"></i> 返回</p>
		<div class="subCard clearfix" v-if="!show">
			<div class="subCard-header">
				<p class="partitle" v-if="title">
					{{ title }}
				</p>
			</div>
			<div class="subCard-item float-l" v-for="(item, index) in peopleList" :key="index" @click="checkItem(item)">
				<div class="subCard-item-content">
					<img src="@/assets/imgs/recordForm.png" alt="" />
					<p class="title">{{ item.title }}</p>
					<p class="subTitle">编号：{{ item.serialNumber }}</p>
					<p class="subTitle mb0">时间：{{ item.createTime }}</p>
				</div>
			</div>
			<div class="nullData" v-if="peopleList.length == 0">暂无数据</div>
		</div>
		<FileDetails
			v-else
			v-for="(item, index) in detailsData"
			:key="index"
			ref="fileDetails"
			:showToBack="checkVal == 2 ? true : false"
			:detailsData="item"
			@toBack="show = false"
		></FileDetails>
	</div>
</template>

<script>
export default {
	data() {
		return {
			peopleList: [],
			title: '',
			show: false,
			detailsData: [],
			pId: null, //最外层表格数据的id
		};
	},
	components: {
		FileDetails: () => import('@/components/FileDetails/index.vue'),
	},
	props: {
		showToBack: {
			type: Boolean,
			default: false,
		},
		checkVal: {
			type: String,
			default: '',
		},
	},
	methods: {
		init(data) {
			this.title = data.title;
			if (data.type) {
				this.getCardList(data);
			} else {
				this.peopleList = data.subList || [];
				this.pId = data.pId;
			}
		},
		// 获取卡片列表
		getCardList(val) {
			let data = {
				contentCode: val.type,
				personId: val.id,
			};
			this.$http
				.get('/diagnosis-api/v1/PersonForms.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.peopleList = res.data.collection || [];
						this.title = null;
					}
				})
				.catch((e) => {});
		},
		getAllForm(data) {
			let par = {
				contentCode: data.type,
				personId: this.pId,
			};
			this.$http
				.get('/diagnosis-api/v1/PersonForms.json', { params: par })
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection || [];
						if (collection.length === 0) return this.$message.warning('暂无数据');
						let flag = collection.every((item) => {
							return item.content == null;
						});
						if (flag) return;
						let flag2 = collection.every((item) => {
							return JSON.parse(item.content).items.length != 0;
						});
						if (!flag2) return;
						this.detailsData = collection.map((item) => {
							return JSON.parse(item.content);
						});
						this.show = true;
					}
				})
				.catch((e) => {});
		},
		// 查看详情
		checkItem(item) {
			if (item.content) {
				this.show = true;
				let data = JSON.parse(item.content);
				if (data && data.items.length != 0) {
					this.detailsData = [JSON.parse(JSON.stringify(data))];
				} else {
					this.$message.warning('暂无数据');
				}
			} else {
				this.getAllForm(item);
			}
		},
		toBack() {
			if (this.show) {
				this.show = false;
				return;
			}
			this.$emit('toBack');
		},
	},
};
</script>

<style lang="scss" scoped>
.toBack {
	width: 68px;
	color: #333;
	background-color: #f5f5f5;
	text-align: center;
	font-size: 12px;
	height: 28px;
	line-height: 28px;
	margin-right: 28px;
	border-radius: 4px;
	margin-bottom: 32px;
	margin-left: 40px;
	cursor: pointer;
	.el-icon-back {
		margin-right: 8px;
	}
}
.subCard {
	padding: 0 40px;
	&-header {
		display: flex;
	}
	.partitle {
		width: 170px;
		text-align: center;
		background-color: #f5f5f5;
		border-radius: 14px;
		color: #333;
		font-size: 12px;
		line-height: 28px;
		margin-bottom: 32px;
	}
	&-item {
		width: calc((100% - 120px) / 5);
		border: 1px solid #dde6f3;
		border-radius: 8px;
		margin-bottom: 30px;
		position: relative;
		margin-right: 30px;
		&:hover {
			box-shadow: -1px 4px 7px 0px rgba(81, 81, 81, 0.08);
		}
		&:nth-child(5n + 1) {
			margin-right: 0;
		}
		&-content {
			padding: 24px;
			text-align: center;
			cursor: pointer;
			img {
				margin-bottom: 16px;
			}
			.title {
				line-height: 22px;
				margin-bottom: 30px;
				height: 44px;
			}
			.subTitle {
				line-height: 12px;
				font-size: 12px;
				color: #999999;
				margin-bottom: 16px;
			}
		}
	}
	.mb0 {
		margin-bottom: 0;
	}
	.nullData {
		text-align: center;
		color: #999999;
		font-size: 20px;
		margin-top: 40px;
	}
}
</style>