<template>
	<div>
		<div v-if="!showSub && !showDet" class="familyContent clearfix">
			<div class="familyContent-item float-l" v-for="(item, index) in peopleList" :key="index">
				<div class="familyContent-item-content">
					<div class="headerClass">
						<p class="title">{{ item.title }}</p>
						<img class="borderR0" v-if="item.id == 3 || item.id == 4" src="@/assets/imgs/smallTab.png" alt="" />
						<img v-else src="@/assets/imgs/datas.png" alt="" />
					</div>
					<p class="showMore" @click="showMore(item)">查看更多<i class="el-icon-arrow-right"></i></p>
				</div>
			</div>
		</div>
		<SubCard ref="subCardVue" v-if="showSub && !showDet" @toBack="showSub = false" :showToBack="true"></SubCard>
		<p class="toBack" @click="showDet = false" v-if="showDet"><i class="el-icon-back"></i> 返回</p>
		<FileDetails
			ref="fileDetails"
			v-if="showDet"
			v-for="(item, index) in detailsData"
			:key="index"
			:detailsData="item"
			:showToBack="false"
		></FileDetails>
	</div>
</template> 

<script>
import SubCard from './subCard.vue';
export default {
	data() {
		return {
			peopleList: [
				{
					id: 1,
					title: '0～6岁儿童健康管理记录表',
					subList: [
						{
							title: '新生儿家庭访视记录表',
							type: 'NNFV',
						},
						{
							title: '1～8月龄儿童健康检查记录表',
							type: 'CA01',
						},
						{
							title: '12～30月龄儿童健康检查记录表',
							type: 'CA12',
						},
						{
							title: '3～6岁儿童健康检查记录表',
							type: 'CA36',
						},
					],
				},
				{
					id: 2,
					title: '孕产妇健康管理记录表',
					subList: [
						{
							title: '第1次产前检查服务记录表',
							type: 'PRI1',
						},
						{
							title: '第2～5次产前随访服务记录表',
							type: 'PRI2',
						},
						{
							title: '产后访视记录表',
							type: 'PPPV',
						},
						{
							title: '产后42天健康检查记录表',
							type: 'PP42',
						},
					],
				},
				{
					id: 3,
					title: '高血压患者随访服务记录表',
					type: 'HPTS',
				},
				{
					id: 4,
					title: 'Ⅱ型糖尿病患者随访服务记录表',
					type: 'DBT2',
				},
				{
					id: 5,
					title: '严重精神障碍患者管理记录表',
					subList: [
						{
							title: '严重精神障碍患者随访服务记录表',
							type: 'SMDP',
						},
					],
				},
				{
					id: 6,
					title: '肺结核患者管理记录表',
					subList: [
						{
							title: '肺结核患者第一次入户随访记录表',
							type: 'PTP1',
						},
						{
							title: '肺结核患者随访服务记录表',
							type: 'PTP2',
						},
					],
				},
				{
					id: 6,
					title: '中医药健康管理服务记录表',
					subList: [
						{
							title: '老年人中医药健康管理服务记录表',
							type: 'TCME',
						},
						{
							title: '6～18月龄儿童中医药健康管理服务记录表',
							type: 'TC06',
						},
						{
							title: '24～36月龄儿童中医药健康管理服务记录表',
							type: 'TC24',
						},
					],
				},
			],
			showSub: false,
			parentId: '',
			detailsData: [],
			showDet: false,
		};
	},
	props: {
		pId: {
			type: String,
			default: '',
		},
	},
	watch: {
		pId: {
			handler(val) {
				this.parentId = val;
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		showMore(item) {
			if (item.id == 3 || item.id == 4) {
				this.getCardList(item);
				return;
			}
			item.pId = this.parentId;
			this.showSub = true;
			this.$nextTick(() => {
				this.$refs.subCardVue.init(item);
			});
		},
		getCardList(val) {
			let data = {
				contentCode: val.type,
				personId: this.pId,
			};
			this.$http
				.get('/diagnosis-api/v1/PersonForms.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection || [];
						if (collection.length === 0) return this.$message.warning('暂无数据');
						let flag = collection.every((item) => {
							return item.content == null;
						});
						if (flag) return;
						let flag2 = collection.every((item) => {
							return JSON.parse(item.content).items.length != 0;
						});
						if (!flag2) return;
						this.detailsData = collection.map((item) => {
							return JSON.parse(item.content);
						});
						this.showDet = true;
					}
				})
				.catch((e) => {});
		},
	},
	components: {
		SubCard,
		FileDetails: () => import('@/components/FileDetails/index.vue'),
	},
};
</script>

<style lang="scss" scoped>
.familyContent {
	padding: 0 40px;
	&-item {
		width: calc((100% - 72px) / 4);
		border: 1px solid #dde6f3;
		border-radius: 8px;
		margin-bottom: 24px;
		background: linear-gradient(118deg, #fcfdff 0%, #f4f6ff 36%, #e4ecff 100%);
		position: relative;
		margin-right: 24px;
		&:hover {
			box-shadow: -1px 4px 7px 0px rgba(81, 81, 81, 0.08);
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
		&-content {
			padding: 20px;
			.headerClass {
				display: flex;
				justify-content: space-between;
				.title {
					width: 185px;
					font-size: 16px;
					line-height: 22px;
					height: 44px;
					color: #333;
					font-weight: bold;
				}
				img {
					width: 32px;
					height: 32px;
					border-radius: 50%;
				}
				.borderR0 {
					border-radius: 0;
				}
			}
			p {
				color: #999;
				font-size: 12px;
				line-height: 12px;
			}
			.showMore {
				text-align: end;
				margin-top: 24px;
				cursor: pointer;
				.el-icon-arrow-right {
					margin-left: 7px;
				}
			}
		}
	}
}
.toBack {
	width: 68px;
	color: #333;
	background-color: #f5f5f5;
	text-align: center;
	font-size: 12px;
	height: 28px;
	line-height: 28px;
	margin-right: 28px;
	border-radius: 4px;
	margin-bottom: 32px;
	margin-left: 40px;
	cursor: pointer;
	.el-icon-back {
		margin-right: 8px;
	}
}
</style>