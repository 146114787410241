<template>
	<div class="otherService">
		<div class="otherService-item" v-if="CSTTData.length != 0">
			<FileDetails ref="fileDetails" v-for="(item, index) in CSTTData" :key="index" :detailsData="item" :showToBack="false"></FileDetails>
		</div>
		<div class="otherService-item" v-if="PITKData.length != 0">
			<FileDetails ref="fileDetails" v-for="(item, index) in PITKData" :key="index" :detailsData="item" :showToBack="false"></FileDetails>
		</div>
		<div class="nullData" v-if="CSTTData.length == 0 && PITKData.length == 0">暂无数据</div>
	</div>
</template>

<script>
export default {
	components: {
		FileDetails: () => import('@/components/FileDetails/index.vue'),
	},
	data() {
		return {
			CSTTData: [],
			PITKData: [],
		};
	},
	props: {
		pId: {
			type: String,
			default: '',
		},
	},
	watch: {
		pId: {
			handler(val) {
				this.getCSTTData(val);
				this.getPITKData(val);
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		getCSTTData(id) {
			let data = {
				contentCode: 'CSTT',
				personId: id,
			};
			this.$http
				.get('/diagnosis-api/v1/PersonForms.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection || [];
						let flag = collection.every((item) => {
							return item.content == null;
						});
						if (flag) return;
						let flag2 = collection.every((item) => {
							return JSON.parse(item.content).items.length != 0;
						});
						if (!flag2) return;
						this.CSTTData = collection.map((item) => {
							return JSON.parse(item.content);
						});
					}
				})
				.catch((e) => {});
		},
		getPITKData(id) {
			let data = {
				contentCode: 'PITK',
				personId: id,
			};
			this.$http
				.get('/diagnosis-api/v1/PersonForms.json', { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						let collection = res.data.collection || [];
						let flag = collection.every((item) => {
							return item.content == null;
						});
						if (flag) return;
						let flag2 = collection.every((item) => {
							return JSON.parse(item.content).items.length != 0;
						});
						if (!flag2) return;
						this.PITKData = collection.map((item) => {
							return JSON.parse(item.content);
						});
					}
				})
				.catch((e) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.otherService {
	padding: 0 40px;
	&-item {
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.nullData {
		text-align: center;
		color: #999999;
		font-size: 20px;
		margin-top: 40px;
	}
}
</style>